<template>
	<cam-box flex="center" p="2" class="el-privacy">
		<cam-box class="article" flex="flex-start" dir="column">
			<h2>Privacy Policy</h2>
			<p>
				Unlike other 'free' products, we're not interested in making a buck off your information, so we try to store as little as possible
				and make it clear <em>what</em> we store.
			</p>
			<hr>
			<h4><i class="lni lni-lock"/>&nbsp; What We Store</h4>
			<ul>
				<li><i class="lni lni-circle-plus"/>&nbsp; Your User ID</li>
				<li><i class="lni lni-circle-plus"/>&nbsp; Your Favorites</li>
			</ul>
			<p>That's it for now. We'll keep you posted with any changes.</p>
			<cam-box flex="flex-end" p="3 0">
				<small align="right"><i class="lni lni-heart"/> &nbsp; Ellis</small>
			</cam-box>
		</cam-box>
	</cam-box>
</template>

<script lang="ts">
export default {
	name: 'el-privacy',
}
</script>

<style lang="stylus" scoped>
@import '~style/mixins.styl'
@import '~style/variables.styl'

.el-privacy {
	.article {
		max-width: 50em
	}

	ul {
		i.lni {
			color: $c-primary-1
		}
	}
}
</style>